@import "../src/styling/variables";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://use.typekit.net/zga3vqd.css");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  body {
    overflow-x: hidden;
    user-select: none;
    background-color: $secondary;

    .scrollButton {
      position: fixed;
      right: 20px;
      bottom: 50px;
      height:40px;
      width: 40px;
      z-index: 100;
      cursor: pointer;
      color: $primary;
      background-color: $secondary;
      box-shadow: 5px 5px $primary;
      border: solid 1px $primary;
      .scrollButtonIcon {
        font-size: 3rem;
        height: 40px;
        width: 40px;
        margin-bottom: 10px;
        padding-right: 5px;
        color: $primary;
      }
      :hover {
        color: $tertiary;
        box-shadow: 5px 5px $tertiary;
        border: solid 1px $tertiary;
        width: 40px;
        .scrollButtonIcon{
          font-size: 3rem;
          height: 40px;
          width: 40px;
          margin-bottom: 10px;
          padding-right: 5px;
          color: $tertiary;
        }
      }
  }
  }
}

//LARGE DESKTOP VIEW
@media screen and (max-width: 4000px) {
  body {
    font-family: Poppins;
    a {
      text-decoration: none;
    }
  }

  [aria-current]:not([aria-current="false"]) {
    text-decoration: underline;
    text-underline-offset: 8px;
    color: $primary;
  }

  // APP STARTS
  .App {
    height: 100vh;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    header {
      height: auto;
      width: 100%;
      z-index: 20;
    }
    .appContent {
      min-height: auto;
      width: 100%;
      margin-bottom: auto;
      z-index: 10;
      position: relative;
      top: 0;
    }
    footer {
      width: 100%;
      z-index: 10;
      bottom: 0;
      position: fixed;
    }
  }
  // APP ENDS

  // NAVBAR STARTS
  .navbarSection {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .navbar {
    width: 100%;
    max-width: 1400px;
    height: 100px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    align-items: center;
    z-index: 100;
    a {
      color: $tertiary;
      li {
        color: $primary;
      }
    }
    h4 {
      font-family: "fino-sans", sans-serif;
      font-weight: 400;
      font-style: normal;
      height: auto;
      width: 500px;
      font-size: 50px;
      padding-left: 10px;
      margin: 0;
      color: $primary;
    }
    .navbarUL {
      padding-right: 10px;
      width: 100%;
      display: flex;
      justify-content: end;
      li {
        list-style-type: none;
        padding-right: 20px;
      }
    }
    .burgerMenuButton {
      display: none;
      z-index: 10;
    }
    .burgerMenuDropDownUL {
      display: none;
    }
  }
  // NAVBAR ENDS

  //CODING STARTS
  .codingPage {
    max-width: 1240px;
    padding-top: 150px;
    margin: 0 auto;
    .codingHero {
      padding: 0 40px;
      display: flex;
      gap: 40px;
      .codingMain {
        width: 70%;
        .codingArticle {
          border: solid 1px $tertiary;
          box-shadow: 10px 10px $tertiary;
          margin-bottom: 40px;
          a img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform 0.2s;
            cursor: pointer;
          }
          .codingArticleText {
            color: $black;
            padding: 10px 0 10px 10px;
            h5 {
              margin: 0;
              font-size: 18px;
              font-weight: 400;
              text-transform: uppercase;
            }
            p {
              margin: 0;
              font-size: 14px;
              font-weight: 200;
              margin-bottom: 10px;
            }
            .codingArticleIcons {
              display: flex;
              gap: 15px;
              color: $primary;
            }
          }
        }
        .codingArticle:hover {
          box-shadow: 15px 15px $tertiary;
          transition: .2s ease-in-out;
        }
      }

      .codingAside {
        width: 30%;
        height: 100%;
        padding: 30px 0;
        border: solid 1px $tertiary;
        box-shadow: 10px 10px $tertiary;
        background-color: $secondary;
        color: $primary;
        h2 {
          margin: 0;
          text-align: center;
          font-size: 30px;
        }
        .codingSkills {
          padding: 20px;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          .codingSkill {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 20px;
            .iconTitle {
              text-align: center;
              font-size: 10px;
            }
          }
        }
      }
    }
  }
  //CODING ENDS

  //PHOTOGRAPHY NAV STARTS
  .photographyAlbumNav {
    position: relative;
    z-index: 50;
    font-weight: 300;
    width: 100%;
    max-width: 1240px;
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    text-decoration: none;
    margin: 0 auto;
    margin-top: 200px;
    padding: 0;
    .photoAlbumNavLink {
      color: $primary;
      padding: 0 5px;
      height: 32px;
      font-size: 16px;
    }
  }

  .photographyNavSection {
    width: 100%;
    height: 100%;
    .photographyNavContainer {
      max-width: 1240px;
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 20px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 150px;
      padding-bottom: 200px;
      a {
        width: 100%;
        height: 100%;
        position: relative;
        color: $secondary;
        display: grid;
        grid-template-columns: 1/2;
        grid-template-rows: 1/2;
        cursor: pointer;
        transition: transform 1s;
        .photographyMenuHeadlineBox {
          width: 100%;
          height: 100%;
          grid-column: 1/2;
          grid-row: 1/2;
          z-index: 30;
          .overlay {
            background-color: $secondary;
            opacity: 20%;
            width: 100%;
            height: 100%;
            z-index: 40;
          }
          .photographyMenuHeadline {
            width: 100%;
            position: absolute;
            top: 45%;
            font-size: 30px;
            text-transform: uppercase;
            line-height: 32px;
            text-align: center;
            z-index: 50;
          }
        }
        .photographyMenuHeadlineBox:hover {
          opacity: 0%;
          transition-duration: 100ms;
        }
        .photographyMenuImg {
          width: 100%;
          height: 100%;
          object-fit: cover;
          grid-column: 1/2;
          grid-row: 1/2;
          z-index: 0;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
  //PHOTOGRAPHY NAV ENDS

  //PHOTO ALBUMS STARTS
  .photoSection {
    width: 100%;
    max-width: 1240px;
    margin: 0 auto;
    margin-bottom: 200px;
    .navbarPhotoDiv {
      position: relative;
      width: 100%;
    }

    .modalSection {
      position: relative;
      width: 100%;
      height: 100%;
      top: 0;
      z-index: 999;
      .innerModalDiv {
        width: 100%;
        height: 100%;
        .galleryModalSection {
          width: 100%;
          height: 100%;
          background-color: rgba(206, 206, 206, 0.601);
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          overflow: hidden;
          .arrowLeft {
            cursor: pointer;
            padding-left: 20px;
          }
          .clickedImage {
            width: auto;
            height: 83%;
            display: flex;
            align-items: center;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          .closeIcon {
            cursor: pointer;
            position: absolute;
            top: 100px;
            right: 20px;
          }
          .arrowRight {
            cursor: pointer;
            padding-right: 20px;
          }
        }
      }
    }

    .photoContainerBox {
      width: 100%;
      .photoContainer {
        margin: 0 auto;
        .albumPhoto {
          padding: 5px;
          img {
            cursor: pointer;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .coronaPhoto {
          margin: 5px;
          padding: 0;
        }
      }
    }
  }
  //PHOTO ALBUMS ENDS

  //ABOUT START
  .aboutContainer {
    max-width: 1240px;
    height: 80vh;
    margin: 0 auto;
    padding-top: 150px;
    .aboutMainDiv {
      border: solid 1px $tertiary;
      box-shadow: 10px 10px $tertiary;
      height: auto;
      padding: 30px;
      margin: auto 0;
      display: flex;
      align-items: start;
      gap: 30px;
      .aboutTextDiv {
        height: 100%;
        width: 50%;
        text-align: justify;
        h4 {
          font-size: larger;
          text-transform: uppercase;
          font-weight: 300;
        }
        p {
          font-weight: 200;
        }
        .creativeSkills {
          padding-top: 20px;
          .creativeSkillsIcons {
            padding-top: 20px;
            .icons {
              width: 40%;
              display: flex;
              justify-content: space-between;
              font-size: 40px;
              color: $primary;
            }
          }
        }
      }
      .aboutImgDiv {
        width: 40%;
        margin: 0 auto;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 100%;
        }
      }
    }
  }
  //ABOUT END

  //CONTACT STARTS
  .contactSection {
    //background-color: pink;
    width: 100%;
    height: 100%;
    padding-top: 150px;
    overflow-y: hidden;
    .contactContainer {
      padding: 20px 0;
      display: flex;
      align-items: center;
      width: 100%;
      height: 70vh;
      margin: 0 auto;
      background-image: linear-gradient(45deg, $tertiary 25%, transparent 25%),
        linear-gradient(135deg, $tertiary 25%, transparent 25%),
        linear-gradient(45deg, transparent 75%, $tertiary 75%),
        linear-gradient(135deg, transparent 75%, $tertiary 75%);
      background-size: 60px 60px; /* Must be a square */
      background-position: 0 0, 30px 0, 30px -30px, 0px 30px; /* Must be half of one side of the square */
      .contactContainerBox {
        max-width: 1240px;
        width: 80%;
        height: auto;
        align-content: center;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        .contactText {
          background-color: $secondary;
          width: 60%;
          height: auto;
          padding: 40px;
          h4 {
            margin: 0;
            text-transform: uppercase;
            letter-spacing: 7px;
            font-size: 26px;
          }
          p {
            font-weight: 200;
            margin-bottom: 15px;
          }
          a {
            color: $primary;
            font-weight: 200;
            :hover {
              text-decoration: underline;
              text-underline-offset: 8px;
            }
          }
        }
      }
    }
  }
  //CONTACT ENDS

  //FOOTER STARTS
  .footer {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 12px;
    background-color: $secondary;
    border-top: solid 1px $primary;
    color: $tertiary;
    z-index: 10;
    padding: 5px;
    a {
      color: $tertiary;
      text-decoration: underline;
      margin: 0;
    }
    p {
      margin: 0;
    }
  }
  //FOOTER ENDS
}
// LARGE DESKTOP ENDS





//SMALL DESKTOP VIEW
@media only screen and (max-width: 1500px) {
  body {
    //background-color: lightblue;

    .photographyNavSection {
      .photographyNavContainer {
        padding-bottom: 100px;
      }
    }

    //ABOUT START
    .aboutContainer {
      height: 80%;
      max-width: 900px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 130px;
      padding-bottom: 20px;
      .aboutMainDiv {
        gap: 20px;
        .aboutTextDiv {
          font-size: 12px;
        }
        .aboutImgDiv {
          height: 100%;
          width: 40%;
          align-self: center;
          //background-color: green;
        }
      }
    }
    //ABOUT END



  //CONTACT STARTS
  .contactSection {
    height: 80vh;
    padding-top: 130px;
    .contactContainer {
      height: 100%;
      .contactContainerBox {
        .contactText {
          padding: 30px;
          h4 {
            font-size: 22px;
          }
          p {
            font-size: 14px;
          }
          a {
            font-size: 14px;
          }
        }
      }
    }
  }
  //CONTACT ENDS
  }
  //BODY END
}
// SMALL DESKTOP VIEW END




//TABLET VIEW
@media only screen and (max-width: 1030px) {
  body {
    //background-color: rgb(243, 172, 172);

    //NAV STARTS
    .navbar {
      h4 {
        width: 330px;
        font-size: 32px;
      }
    }
    //NAV ENDS


  //CODING STARTS
    .codingPage {
      max-width: 1240px;
      .codingHero {
        gap: 30px;

        .codingAside {
          padding: 20px 0;
          .codingSkills {
            grid-template-columns: 1fr 1fr;
            padding: 10px;
            .codingSkill {
              align-items: center;
              .iconImgBox {
                .iconImg {
                  height: 30px;
                }
              }
            }
          }
        }
      }
    }
  //CODING ENDS

    //ABOUT START
    .aboutContainer {
      height: 90vh;
      align-content: center;
      .aboutMainDiv {
        flex-direction: column;
        gap: 50px;
        .aboutTextDiv {
          margin: 0 auto;
          width: 90%;
        }
        .aboutImgDiv {
          width: 60%;
          margin: 0 auto;
        }
      }
    }
    //ABOUT END

//CONTACT STARTS
    .contactSection {
      height: 100%;
      padding-top: 110px;
      padding-bottom: 0px;
      .contactContainer {
        height: 80vh;
        .contactContainerBox {
          display: flex;
          flex-direction: column-reverse;
          .contactText {
            width: 100%;
            height: auto;
            padding: 20px;
            h4 {
              font-size: 16px;
            }
            p {
              font-weight: 200;
              margin-bottom: 15px;
              font-size: 12px;
            }
            a {
              font-size: 12px;
            }
          }
        }
      }
    }
    //CONTACT ENDS

  }
  //BODY END
}
// TABLET VIEW END





//MOBILE VIEW
@media only screen and (max-width: 700px) {
  body {
    //background-color: rgb(251, 225, 176);

    .scrollButton {
      height:30px;
      width: 30px;
      .scrollButtonIcon {
        height: 30px;
        width: 30px;
        padding-right: 2px;
      }
      :hover {
        color: $tertiary;
        box-shadow: 5px 5px $tertiary;
        border: solid 1px $tertiary;
        width: 30px;
        .scrollButtonIcon{
          font-size: 2rem;
          height: 30px;
          width: 30px;
          padding-right: 2px;
        }
      }
  }

    .navbar {
      .navbarUL {
        display: none;
      }
      .burgerMenuButton {
        position: absolute;
        top: 20px;
        right: 20px;
        display: inline;
        z-index: 350;
      }
      .burgerMenuDropDownShow {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
      }
      .burgerMenuDropDownHidden {
        position: absolute;
        left: -100%;
      }
      .burgerMenuDropDownUL {
        z-index: 300;
        display: inline;
        position: absolute;
        top: 0;
        left: 0;
        background-color: $secondary;
        opacity: 90%;
        width: 100%;
        height: 100vh;
        padding: 0;
        margin: 0;
        .burgerLogo {
          font-size: 32px;
          margin-bottom: 2rem;
          padding-left: 10px;
          padding-top: 5px;
          margin-top: 26px;
        }
        a {
          list-style-type: none;
          li {
            border-bottom: 2px solid rgba(255, 255, 255, 0.569);
            padding: 10px;
            margin: 20px 20px;
          }
        }
      }
      .burgerSoMeIcon {
        display: flex;
        justify-content: center;
        padding: 30px;
      }
    }


    //CODING STARTS
    .codingPage {
      .codingHero {
        padding: 0 10px;
        gap: 20px;
        .codingMain {
          width: 55%;
          .codingArticle {
            margin-bottom: 40px;
            .codingArticleText {
              h5 {
                font-size: 14px;
              }
              p {
                font-size: 12px;
              }
              .codingArticleIcons {
                gap: 10px;
              }
            }
          }
        }

        .codingAside {
          width: 35%;
          padding: 5px 0;
          h2 {
            font-size: 18px;
          }
          .codingSkills {
            padding: 3px;
            .codingSkill {
              .iconImgBox {
                .iconImg {
                  height: 17px;
                }
              }
            }
          }
        }
      }
    }
  //CODING ENDS




    //PHOTOGRAPHY NAV STARTS
    .photographyAlbumNav {
      margin-top: 120px;
      z-index: 500;
      .photoAlbumNavLink {
        height: 28px;
        font-size: 10px;
      }
    }

    .photographyNavSection {
      .photographyNavContainer {
        padding-top: 100px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 8px;
        a {
          .photographyMenuHeadlineBox {
            .photographyMenuHeadline {
              font-size: 14px;
            }
          }
        }
      }
    }
    //PHOTOGRAPHY NAV ENDS

    //ABOUT START
    .aboutContainer {
      height: 100%;
      padding-top: 100px;
      display: flex;
      .aboutMainDiv {
        height: auto;
        align-content: center;
        gap: 10px;
        padding: 10px;
        .aboutTextDiv {
          width: 100%;
          text-align: justify;
          p {
            font-size: 12px;
          }
        }
        .aboutImgDiv {
          width: 60%;
        }
      }
    }
    //ABOUT END



    //CONTACT STARTS
    .contactSection {
      height: auto;
      padding-top: 90px;
      padding-bottom: 10px;
      .contactContainer {
        padding: 15px 0;
        width: 100%;
        .contactContainerBox {
          width: 80%;
          .contactText {
            width: 100%;
            height: auto;
            padding: 20px;
            h4 {
              margin: 0;
              text-transform: uppercase;
              letter-spacing: 7px;
              font-size: 16px;
            }
            p {
              font-weight: 200;
              margin-bottom: 15px;
              font-size: 11px;
            }
            a {
              color: $primary;
              font-weight: 200;
              font-size: 11px;
              :hover {
                text-decoration: underline;
                text-underline-offset: 8px;
              }
            }
          }
        }
      }
    }
    //CONTACT ENDS

    .footer {
      font-size: 10px;
      flex-direction: column;
      gap: 0;
    }
  }
}
// MOBILE VIEW END
